'use client'

import { useState, useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/navigation'

import Logo from '../Logo'
import Utils from '@/lib/Utils'
import { useUserStore } from '@/lib/store/user'

export default function Header(props) {
  const setUser = useUserStore(state => state.setUser)

  const router = useRouter();
  const [currUser, setCurrUser] = useState(null);
  const [top, setTop] = useState(true)

  // detect whether user has scrolled the page down by 10px
  const scrollHandler = () => {
    window.pageYOffset > 10 ? setTop(false) : setTop(true)
  }

  useEffect(() => {
    if (Utils.hasWindowObject() && currUser == null) {
      setCurrUser(Utils.getCurrentUser());
      setUser(Utils.getCurrentUser());
    }
  }, [props, currUser])

  useEffect(() => {
    scrollHandler()
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
  }, [top])

  const logoutCurrUser = async () => {
    try {
      await Utils.logout();
      setCurrUser(null);
      setUser(null);
      router.replace('/');
    } catch (error) {
      console.log("error ----> ", error.message);
    }
  }

  const role = currUser?.user?.role;
  const priceLink = props?.priceLink || '#pricing';

  return (
    <header className={`bg-violet-900 fixed w-full z-30 transition duration-300 ease-in-out ${!top ? 'backdrop-blur-sm shadow-lg' : ''}`}>
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">
          <div className="navbar bg-tra text-white">
            <div className="navbar-start">
              <div className="shrink-0">
                <Logo />
              </div>
              <Link href="/" className='text-xl font-extrabold hover:text-green-300 cursor-pointer'>Make Poster</Link>
            </div>
            <div className="navbar-end hidden md:flex">
              <ul className="menu menu-horizontal px-1">
                <li><Link href={priceLink} className="font-bold focus:text-green-500 active:text-green-500 hover:text-green-400">Pricing</Link></li>

                {currUser &&
                  <>
                    <li>
                      <Link href="/dashboard" className="font-bold focus:text-green-500 active:text-green-500 hover:text-green-400">Dashboard</Link>
                    </li>
                    <li>
                      <Link href="/custom-poster" className="font-bold focus:text-green-500 active:text-green-500 hover:text-green-400">Custom</Link>
                    </li>
                  </>
                }

                {!currUser &&
                  (<>
                    <li><Link href="/signup" className="font-bold hover:text-green-400">SignUp</Link></li>
                  </>)
                }
              </ul>
              {!currUser &&
                (<>
                  <Link href="/signin" className="btn btn-sm font-bold text-slate-950 hover:bg-green-400 active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300">Login</Link>
                </>)
              }
              {currUser &&
                (<>
                  <Link href="#" onClick={() => {
                    logoutCurrUser();
                  }} className='btn btn-sm font-bold text-slate-950 hover:text-white hover:bg-violet-400 active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300'>Logout</Link>
                </>)
              }
            </div>
            <div className="navbar-end md:hidden">
              <div className="dropdown dropdown-end">
                <div aria-label='open-list' tabIndex="0" role="button" className="btn btn-ghost btn-circle text-white">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" /></svg>
                </div>
                <ul tabIndex="0" className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
                  <li><Link href={priceLink} className='text-violet-900  font-bold text-center'>Pricing</Link></li>

                  {!currUser &&
                    (<>
                      <li><Link href="/signup" className='text-violet-900 font-bold'>SignUp</Link></li>
                      <li><Link href="/signin" className='btn btn-sm font-bold text-white bg-violet-800 hover:text-white hover:bg-violet-400 active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300'>Login</Link></li>
                    </>)
                  }
                  {currUser &&
                    (<>
                      <li><Link href="/dashboard" className='text-violet-900 font-bold text-center'>Dashboard</Link></li>
                      <li><Link href="/custom-poster" className='text-violet-900 font-bold text-center'>Custom</Link></li>
                      <li>
                        <Link href="#" onClick={() => {
                          logoutCurrUser();
                        }} className='btn btn-sm font-bold text-white bg-violet-800 hover:text-white hover:bg-violet-400 active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300'>Logout</Link>
                      </li>
                    </>)
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header >
  )
}
