import Image from 'next/image'
import Link from 'next/link'

export default function Logo(props) {
  const { withBg } = props;
  let src = '/images/logo-nonbg.png';

  if(withBg) {
    src = '/images/logo.png';
  }
  return (
    <Link href="/" className="flex justify-center items-center" aria-label="Cruip">
      <Image priority src={src} className='rounded-md' width="50" height="50" alt="Make Poster Logo" />
    </Link>
  )
}
