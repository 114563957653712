import Link from 'next/link'
import Logo from '../Logo'

export default function Footer() {
  return (

    <footer className="footer p-10 bg-base-200">
      <aside>
        <Logo withBg={"true"} />
        <p>&copy; makeposter.live. All rights reserved. </p>
      </aside>
      <nav>
        <h2 className="font-bold text-lg text-violet-900">LINKS</h2>
        <Link href='#pricing' className="link link-hover">Pricing</Link>
        <Link href='/signup' className="link link-hover">Signup</Link>
        <Link href='/signin' className="link link-hover">Login</Link>
      </nav>
      <nav>
        <h2 className="font-bold text-lg text-violet-900">Legal</h2>
        <Link href="/terms" className="link link-hover">Terms of use</Link>
        <Link href="/privacy-policy" className="link link-hover">Privacy policy</Link>
      </nav>
    </footer>
  )
}
