import { NextResponse } from 'next/server';
import { HttpStatusCode } from 'axios';
import { RESPONSE } from '../constant';

export default class Utils {
    static async handleResponseError(response) {
        if (response.type === "ERROR") {
            throw Error(response.message);
        }

        return response
    }

    static hasWindowObject() {
        return typeof window !== 'undefined';
    }

    static async logout() {
        if (this.hasWindowObject()) {
            localStorage.removeItem('currUser');
        }
    }

    static getCurrentUser() {
        if (this.hasWindowObject()) {
            return JSON.parse(window.localStorage.getItem('currUser'));
        }

        return null;
    }

    static getLocalStorage(key) {
        if (this.hasWindowObject()) {
            return localStorage.getItem(key);
        }

        return null;
    }

    static setLocalStorage(key, value) {
        if (this.hasWindowObject()) {
            localStorage.setItem(key, value);
        }
    }

    static throwError = (code=HttpStatusCode.BadGateway, errorType, errorMessage) => (error) => {
        if (!error) error = new Error(errorMessage || 'Default Error')

        error.code = code
        error.errorType = errorType

        throw error
    }

    static sendSuccess = (response) => {
        return NextResponse.json(
            {
                response: (response || {}),
                message: 'Data Fetched!',
                type: RESPONSE.SUCCESS
            },
            { status: HttpStatusCode.Created },
        );
    }

    static sendError = (message = '') => (error) => {
        const data = {
            type: RESPONSE.ERROR,
            message,
            response: {}
        }

        if (error) {
            data.message = (error.message || error)
        }

        return NextResponse.json(
            data,
            { status: HttpStatusCode.BadRequest },
        );
    }
    
    static  wrapText = (context, text, x, y, maxWidth, lineHeight) => {
        var words = text.split(' '),
            line = '',
            lineCount = 0,
            i,
            test,
            metrics;

        for (i = 0; i < words.length; i++) {
            test = words[i];
            metrics = context.measureText(test);
            while (metrics.width > maxWidth) {
                // Determine how much of the word will fit
                test = test.substring(0, test.length - 1);
                metrics = context.measureText(test);
            }
            if (words[i] != test) {
                words.splice(i + 1, 0, words[i].substr(test.length))
                words[i] = test;
            }

            test = line + words[i] + ' ';
            metrics = context.measureText(test);

            if (metrics.width > maxWidth && i > 0) {
                context.fillText(line, x, y);
                line = words[i] + ' ';
                y += lineHeight;
                lineCount++;
            } else {
                line = test;
            }
        }
        context.fillText(line, x, y);
    }
}  