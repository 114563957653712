export const ROLES = {
    SUPERADMIN: 'SUPERADMIN',
    USER: 'USER'
};

export const PLAN_TYPE = {
    SILVER: 'Silver',
    GOLD: 'Gold',
    TRIAL: 'Trial'
}
export const COUPEN = "LAUNCH";

export const ACTION = {
    TRUE: true,
    FALSE: false
};

export const RESPONSE = {
    SUCCESS: "success",
    ERROR: "error"
};

export const JWT_INFO = {
    SECRET: "MakePoster2024",
}

export const BUSINESS_PLAN = {
    "id": 3,
    "check": "Check",
    "object": "product",
    "active": true,
    "attributes": [],
    "created": Date.now(),
    "default_price": {
        "id": "price_2",
        "object": "price",
        "active": true,
        "billing_scheme": "per_unit",
        "created": Date.now(),
        "currency": "cad",
        "custom_unit_amount": null,
        "livemode": false,
        "lookup_key": null,
        "metadata": {},
        "nickname": null,
        "product": 2,
        "recurring": {
            "aggregate_usage": null,
            "interval": "month",
            "interval_count": 1,
            "trial_period_days": null,
            "usage_type": "licensed"
        },
        "tax_behavior": "unspecified",
        "tiers_mode": null,
        "transform_quantity": null,
        "type": "recurring",
        "unit_amount": 0,
        "unit_amount_decimal": "0"
    },
    "description": "Premium features tailored for businesses with high-volume scheduling and management needs.",
    "features": [
        {
            "name": "Every Gold Features"
        },
        {
            "name": "POS integration"
        },
        {
            "name": "Real-time sales reporting"
        },
        {
            "name": "Multi-location staffing"
        }
    ],
    "images": [],
    "livemode": false,
    "metadata": {
        "buttonText": "Contact Us",
        "class": "bg-gray-900 hover:bg-green-550"
    },
    "name": "Platinum",
    "package_dimensions": null,
    "shippable": null,
    "statement_descriptor": null,
    "tax_code": null,
    "type": "service",
    "unit_label": null,
    "updated": Date.now(),
    "url": null
}

export const POSTERS = [
    { id: 1, image: "/images/posters/hb_poster1.png", croppedImage:"https://res.cloudinary.com/dt5xc1sel/image/upload/v1714358396/cropped-posters/qdrl792ik7k5qifhvfxg.png", title: "Poster 1", photoHeight: 200, photoWidth: 195, orignalHeight: 1221,  xPoint: 336, yPoint: 687 },
    { id: 2, image: "/images/posters/hb_poster2.png", croppedImage: "https://res.cloudinary.com/dt5xc1sel/image/upload/v1714348817/cropped-posters/tmqwlri4igjyfklsqyis.png", title: "Poster 2", photoHeight: 200, photoWidth: 180, orignalHeight: 1221,  xPoint: 251, yPoint: 450 },
    { id: 3, image: "https://img.freepik.com/free-vector/wild-nature-banner-template_23-2148943836.jpg", title: "Poster 3", photoHeight: 200, photoWidth: 200 },
    { id: 4, image: "/images/posters/hb_poster3.png", title: "Poster 3", photoHeight: 193, photoWidth: 200, xPoint: 144, yPoint: 400, orignalHeight: 775, hasText: true  },
    { id: 5, image: "/images/posters/poster.png", title: "Poster 4", photoHeight: 177, photoWidth: 200, orignalHeight: 1204 },
];